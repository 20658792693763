import { Component } from "@angular/core";
import { AppService } from "@core/app/app.service";
import { GTMService } from "@core/app/gtm.service";
import { UserService } from "@core/app/user.service";
import { faFacebook, faHouzz, faInstagram, faPinterest } from "@fortawesome/free-brands-svg-icons";

@Component({
	selector: "cm-footer",
	template: `
		<div class="container-fluid pt-3 text-green">
			<div class="row">
				<div class="col-12 col-md-4 my-1 text-center">
					<div class="d-inline-block mx-1 text-left">
						<div class="py-2">
							<a routerLink="/">
								<img
									[src]="app.logo$ | async | image : 's'"
									alt="{{ app.dealer$ | async }}"
									class="logo img-fluid"
								/>
							</a>
						</div>
						<div class="py-2">
							<a
								href="tel:{{ app.phoneNumber$ | async }}"
								class="text-green h4 font-weight-bold"
								(click)="linkClicked('Link Click', 'Phone Call - Footer', 'click')"
							>
								<div class="row">
									<div class="col-2 text-center">
										<img
											src="https://dfm-cdn.com/static/12/icon-phone-green.png"
											alt="phone"
											class="img-fluid"
										/>
									</div>
									<div class="col-10">
										{{ app.phoneNumber$ | async }}
									</div>
								</div>
							</a>
						</div>
						<div class="py-2">
							<a [href]="app.addressLink$ | async" class="text-green h5 font-weight-normal">
								<div class="row">
									<div class="col-2 text-center">
										<img
											src="https://dfm-cdn.com/static/12/icon-pin-green.png"
											alt="pin"
											class="img-fluid"
										/>
									</div>
									<div class="col-10">
										{{ app.addressLine1$ | async }}<br />{{ app.addressLine2$ | async }}
									</div>
								</div>
							</a>
						</div>
						<div class="py-2">
							<div class="row">
								<div class="col-2 text-center">
									<img
										src="https://dfm-cdn.com/static/12/icon-hours-green.png"
										alt="clock"
										class="img-fluid"
									/>
								</div>
								<div class="col-10">
									<cm-hours class="h5 font-weight-normal"></cm-hours>
								</div>
							</div>
						</div>
						<div class="py-2">
							<div class="row">
								<div class="col-12 text-center h3">
									<a
										href="https://www.facebook.com/RycengaBuildingCenter/"
										class="text-green mx-2"
										target="_blank"
										><fa-icon [icon]="faFacebook" [fixedWidth]="true"></fa-icon
									></a>
									<a
										href="https://www.instagram.com/rycengabuildingcenter/"
										class="text-green mx-2"
										target="_blank"
										><fa-icon [icon]="faInstagram" [fixedWidth]="true"></fa-icon
									></a>
									<a
										href="https://in.pinterest.com/rycenga/_saved/"
										class="text-green mx-2"
										target="_blank"
										><fa-icon [icon]="faPintrest" [fixedWidth]="true"></fa-icon
									></a>
									<a
										href="https://www.houzz.com/professionals/kitchen-and-bath-designers/rycenga-building-center-pfvwus-pf~1921351314"
										class="text-green mx-2"
										target="_blank"
										><fa-icon [icon]="faHouzz" [fixedWidth]="true"></fa-icon
									></a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-4 my-1">
					<div class="row">
						<div class="col-6">
							<div class="h4 font-weight-bold text-uppercase text-green">PRODUCTS</div>
							<ul class="list-unstyled">
								<li><a routerLink="/products/windows-doors" class="text-green">Windows & Doors</a></li>
								<li><a routerLink="/products/kitchen-bath" class="text-green">Kitchen & Bath</a></li>
								<li>
									<a routerLink="/products/building-supplies" class="text-green">Building Supplies</a>
								</li>
								<li><a routerLink="/products/lumber" class="text-green">Lumber</a></li>
								<li><a routerLink="/products/roofing" class="text-green">Roofing</a></li>
								<li><a routerLink="/products/decking" class="text-green">Decking</a></li>
								<li><a routerLink="/products/insulation" class="text-green">Insulation</a></li>
								<li><a routerLink="/products/siding-trim" class="text-green">Siding & Trim</a></li>
								<li><a routerLink="/products/flooring-tile" class="text-green">Flooring & Tile</a></li>
								<li><a routerLink="/products/paint-stain" class="text-green">Paint & Stain</a></li>
								<li>
									<a routerLink="/products/hardware-tools" class="text-green">Hardware & Tools</a>
								</li>
								<li><a routerLink="/products/seasonal" class="text-green">Seasonal</a></li>
							</ul>
						</div>
						<div class="col-6">
							<div class="h4 font-weight-bold text-uppercase text-green">SERVICES</div>
							<ul class="list-unstyled">
								<li>
									<a routerLink="/services/kitchen-bath-design" class="text-green"
										>Kitchen & Bath Design</a
									>
								</li>
								<li>
									<a routerLink="/services/insulation" class="text-green"
										>Insulation & Installation</a
									>
								</li>
								<li>
									<a routerLink="/services/estimating" class="text-green">Estimating</a>
								</li>
								<li>
									<a routerLink="/services/delivery" class="text-green">Delivery Service</a>
								</li>
							</ul>
							<div class="h4 font-weight-bold text-uppercase text-green">QUICK LINKS</div>
							<ul class="list-unstyled">
								<li><a routerLink="/about" class="text-green">About</a></li>
								<li><a routerLink="/products" class="text-green">Products</a></li>
								<li><a routerLink="/services" class="text-green">Services</a></li>
								<li><a routerLink="/contractors" class="text-green">Contractors</a></li>
								<li><a routerLink="/contact" class="text-green">Contact</a></li>
								<li><a routerLink="/jobs/application" class="text-green">Join Our Team</a></li>
							</ul>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-4 my-1">
					<div class="h4 font-weight-bold text-uppercase text-green">OUR PARTNERS</div>
					<div class="row">
						<div class="col-4 my-1">
							<div class="text-center embed-responsive embed-responsive-16by9" style="max-height: 125px;">
								<a href="https://www.doitbest.com/" target="_blank">
									<img
										src="https://dfm-cdn.com/static/12/logo-doitbest.png"
										loading="lazy"
										alt="Do It Best"
										class="embed-responsive-item"
										style="object-fit:contain"
										itemprop="image"
								/></a>
							</div>
						</div>
						<div class="col-4 my-1">
							<div class="text-center embed-responsive embed-responsive-16by9" style="max-height: 125px;">
								<a href="https://www.lakeshorehomebuilders.com/" target="_blank">
									<img
										src="https://dfm-cdn.com/static/12/logo-hba.png"
										loading="lazy"
										alt="HBA"
										class="embed-responsive-item"
										style="object-fit:contain"
										itemprop="image"
								/></a>
							</div>
						</div>
						<div class="col-4 my-1">
							<div class="text-center embed-responsive embed-responsive-16by9" style="max-height: 125px;">
								<a href="https://www.retailers.com/" target="_blank">
									<img
										src="https://dfm-cdn.com/static/12/mra.png"
										loading="lazy"
										alt="MRA"
										class="embed-responsive-item"
										style="object-fit:contain"
										itemprop="image"
								/></a>
							</div>
						</div>
						<div class="col-4 my-1">
							<div class="text-center embed-responsive embed-responsive-16by9" style="max-height: 125px;">
								<a href="https://grandhavenchamber.org/" target="_blank">
									<img
										src="https://dfm-cdn.com/static/12/logo-chamber.png"
										loading="lazy"
										alt="Chamber"
										class="embed-responsive-item"
										style="object-fit:contain"
										itemprop="image"
								/></a>
							</div>
						</div>
						<div class="col-4 my-1">
							<div class="text-center embed-responsive embed-responsive-16by9" style="max-height: 125px;">
								<a href="https://www.thembsa.org/" target="_blank">
									<img
										src="https://dfm-cdn.com/static/12/mbsa-logo.png"
										loading="lazy"
										alt="MBSA"
										class="embed-responsive-item"
										style="object-fit:contain"
										itemprop="image"
								/></a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row bg-green text-white py-2">
				<div class="col-12 text-center">
					<a class="text-white mx-1" routerLink="/terms-of-service">Terms of Service</a> |
					<a class="text-white mx-1" routerLink="/privacy-policy">Privacy Policy</a> |
					<a href="//designforcemarketing.com" class="text-white mx-1" target="_blank"
						>Powered by Design Force Marketing</a
					>
					<ng-container *ngIf="userService.loggedIn$ | async">
						|
						<a
							*ngIf="
								(userService.isEmp$ | async) || (userService.hasPermission$(['site', 'admin']) | async)
							"
							routerLink="/employee-portal"
							class="text-white mx-1"
							>Portal</a
						>
					</ng-container>
					|
					<a
						href="javascript:void(0)"
						class="text-white mx-1"
						*ngIf="!(userService.loggedIn$ | async)"
						(click)="showLogin = true"
					>
						Log In
					</a>
					<a
						href="javascript:void(0)"
						class="text-white mx-1"
						*ngIf="userService.loggedIn$ | async"
						(click)="userService.logOut()"
					>
						Log Out
					</a>
				</div>
			</div>
		</div>
		<cm-login-modal [(show)]="showLogin" (loggedIn)="showLogin = false"></cm-login-modal>
	`,
	styles: [
		`
			:host {
				display: block;
			}
		`,
	],
})
export class FooterComponent {
	year = new Date().getFullYear();
	faFacebook = faFacebook;
	faHouzz = faHouzz;
	faPintrest = faPinterest;
	faInstagram = faInstagram;
	showLogin: boolean = false;

	constructor(public app: AppService, private gtmService: GTMService, public userService: UserService) {}
	linkClicked(category: string, label: string, action: string) {
		this.gtmService.track(category, action, label);
	}
}
