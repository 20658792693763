import { DOCUMENT } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { ChangeDetectionStrategy, Component, Inject, Renderer2 } from "@angular/core";
import { Router } from "@angular/router";
import { faFacebook, faHouzz, faInstagram, faPinterest } from "@fortawesome/free-brands-svg-icons";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { NgbTypeaheadSelectItemEvent } from "@ng-bootstrap/ng-bootstrap";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { AppService } from "../app.service";
import { GTMService } from "../gtm.service";
import { ConfigService } from "../config.service";

// TODO: generate this component at build time to get rid of the ngSwitch, so the unused templates can be treeshaken

@Component({
	selector: "cm-header",
	template: `
		<header id="nav-header" class="header shadow bg-white">
			<div class="container-fluid">
				<ng-container *ngIf="messageBanner$ | async as messageBanner">
					<div class="row text-center bg-primary text-white font-weight-bold font-bold py-1">
						<div class="col-12 text-center" [innerHTML]="messageBanner"></div>
					</div>
				</ng-container>
				<div
					class="row align-items-center align-items-lg-start"
					itemscope
					itemtype="http://schema.org/WPHeader"
				>
					<div class="col-6 col-lg-2 d-flex justify-content-center py-2">
						<a routerLink="/">
							<img
								[src]="app.logo$ | async | image"
								alt="{{ app.dealer$ | async }}"
								class="logo img-fluid"
							/>
						</a>
					</div>
					<div class="col-6 col-lg-10">
						<div class="row bg-green text-white d-none d-lg-block  py-2">
							<div class="col-12 d-flex justify-content-end">
								<a [href]="app.addressLink$ | async" class="address text-white mx-2">
									<img
										src="https://dfm-cdn.com/static/12/icon-pin-white.png"
										alt="pin"
										class="img-fluid mx-1"
									/>
									{{ app.addressLine1$ | async }} {{ app.addressLine2$ | async }}
								</a>
								<span class="mx-2"
									><img
										src="https://dfm-cdn.com/static/12/icon-hours-white.png"
										alt="clock"
										class="img-fluid mx-1" /><cm-hours2 class="mx-2"></cm-hours2
								></span>
								<a
									href="tel:{{ app.phoneNumber$ | async }}"
									class="text-white mx-1"
									(click)="linkClicked('Link Click', 'Phone Call - Header', 'click')"
									><img
										src="https://dfm-cdn.com/static/12/icon-phone-white.png"
										alt="phone"
										class="img-fluid mx-2"
									/>{{ app.phoneNumber$ | async }}</a
								>
								<a
									href="https://www.facebook.com/RycengaBuildingCenter/"
									class="text-white mx-1"
									target="_blank"
									><fa-icon [icon]="faFacebook" [fixedWidth]="true"></fa-icon
								></a>
								<a
									href="https://www.instagram.com/rycengabuildingcenter/"
									class="text-white mx-1"
									target="_blank"
									><fa-icon [icon]="faInstagram" [fixedWidth]="true"></fa-icon
								></a>
								<a
									href="https://in.pinterest.com/rycenga/_saved/"
									class="text-white mx-1"
									target="_blank"
									><fa-icon [icon]="faPintrest" [fixedWidth]="true"></fa-icon
								></a>
								<a
									href="https://www.houzz.com/professionals/kitchen-and-bath-designers/rycenga-building-center-pfvwus-pf~1921351314"
									class="text-white mx-1"
									target="_blank"
									><fa-icon [icon]="faHouzz" [fixedWidth]="true"></fa-icon
								></a>
							</div>
						</div>
						<div class="row">
							<div
								class="col-12 col-lg-9 d-flex justify-content-end justify-content-lg-start align-items-center pl-0 pr-1 menu-holder"
							>
								<a routerLink="/shop" class="btn btn-primary py-1 d-lg-none mr-3">Shop</a>
								<cm-menu2 [appMenuid]="1" rootStyle="inline" subStyle="unstyled"></cm-menu2>
							</div>
							<div class="col-lg-3 d-none d-lg-flex align-items-center px-1">
								<a routerLink="/shop" class="btn btn-primary py-1">Shop</a>
								<cm-search
									placeholder="Search..."
									buttonClass="primary"
									[searchFn]="doTypeaheadSearch"
									(result)="selected($event)"
									(search)="search($event)"
									[focusFirst]="false"
									[buttonIcon]="faSearch"
									class="flex-grow-1 py-1 pl-1 pr-3 search"
								></cm-search>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
		<cm-login-modal [(show)]="showLogin" (loggedIn)="showLogin = false"></cm-login-modal>
	`,
	styleUrls: ["./header.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
	faFacebook = faFacebook;
	faHouzz = faHouzz;
	faPintrest = faPinterest;
	faSearch = faSearch;
	faInstagram = faInstagram;

	showLogin = false;
	showProductsBS = new BehaviorSubject(false);
	metaInfos$ = this.http.post("/api/statement/GetMetaInfos", {}).pipe(shareReplay(1));
	messageBanner$ = this.configService.getSetting$("messageBanner");

	constructor(
		public app: AppService,
		private renderer: Renderer2,
		private router: Router,
		private http: HttpClient,
		@Inject(DOCUMENT) private document: Document,
		private gtmService: GTMService,
		private configService: ConfigService,
	) {}

	doTypeaheadSearch = (text: string): Observable<any[]> => {
		if (text) {
			[
				"-",
				"+",
				"~",
				"/",
				"\\",
				"<",
				">",
				"'",
				'"',
				":",
				"*",
				"$",
				"#",
				"@",
				"(",
				")",
				"!",
				",",
				".",
				"?",
				"`",
				"=",
				"%",
				"&",
				"^",
			].forEach((row) => {
				text = text.replace(row, " ").trim();
			});
			return this.metaInfos$.pipe(
				map((metaInfos) => {
					return ((metaInfos as any).results as any[]).filter((row) => {
						return (
							row.name?.toLowerCase().includes(text.toLowerCase()) ||
							row.label?.toLowerCase().includes(text.toLowerCase()) ||
							row.meta_desc?.toLowerCase().includes(text.toLowerCase()) ||
							row.meta_kywd?.toLowerCase().includes(text.toLowerCase()) ||
							row.page_desc?.toLowerCase().includes(text.toLowerCase()) ||
							row.closing_desc?.toLowerCase().includes(text.toLowerCase())
						);
					});
				}),
			);
		} else {
			return of([]);
		}
	};

	toggleMenu() {
		if (this.showProductsBS) {
			this.renderer.removeClass(this.document.body, "modal-open");
		} else {
			this.renderer.addClass(this.document.body, "modal-open");
		}
		this.showProductsBS.next(!this.showProductsBS.value);
	}

	selected(selectedItem: NgbTypeaheadSelectItemEvent) {
		this.router.navigateByUrl(selectedItem.item.href);
		this.renderer.removeClass(this.document.body, "modal-open");
		this.showProductsBS.next(false);
	}

	search(searchString: string) {}

	linkClicked(category: string, label: string, action: string) {
		this.gtmService.track(category, action, label);
	}
}
